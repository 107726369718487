export default {
	name: "translateMixin",
	data: function () {
		return {
			translation: {
				en: {
					formLabel__interestUCLS: 'UCLS',
					formHelpText__interestUCLS: 'Private label production',

					formLabel__interestEVENSWISS: 'EVENSWISS',
					formHelpText__interestEVENSWISS: 'Products distribution',

					formLabel__interestALOVE: 'ALÔVE',
					formHelpText__interestALOVE: 'Products distribution',

					formLabel__interestSUISSETTE: 'SUISSETTE',
					formHelpText__interestSUISSETTE: 'Products distribution',

					formLabel__companyNameInput: 'Company name',
					formPlaceholder__companyNameInput: 'Enter company name',

					formLabel__customerFamilyNameInput: 'Last name',
					formPlaceholder__customerFamilyNameInput: 'Enter last name',

					formLabel__customerGivenNameInput: 'First name',
					formPlaceholder__customerGivenNameInput: 'Enter first name',

					formLabel__customerCountrySelect: 'From what country',

					formLabel__emailInput: 'Email address',
					formPlaceholder__emailInput: 'contact@domain.com',

					formLabel__websiteInput: 'Website',
					formPlaceholder__websiteInput: 'domain.com',

					formLabel__countryOfInterest: 'Countries of interest',
					formPlaceholder__countryOfInterest: 'Start typing country name',
					formHelpText__countryOfInterest: 'Countries selected:',

					formLabel__businessType: 'Business types',

					formLabel__Notes:'Notes',
					formPlaceholder__Notes: 'Add notes to make it easier to remember what the conversation was about',

					interfaceString__distributor: 'Distributor',
					interfaceString__retailer_department_store: 'Retailer or department store',
					interfaceString__online_platform: 'Online platform',
					interfaceString__pharma_clinics_dermatologist: 'Pharma chain, clinic or dermatologist',
					interfaceString__beauticians: 'Beautician',

					formLabel__otherBusinessType: 'Other type of business',
					formPlaceholder__otherBusinessType: 'You can type in any missing type',

					interfaceString__Click_to_remove: 'Click to remove',
					interfaceString__Go_to_main_page: 'Go to main page',
					interfaceString__Create_new_item: 'Create new item',
					interfaceString__Clear_inputs: 'Clear form',

					formButton__Add_image: 'Add images',
					formButton__Save: 'Save',
					formButton__Delete: 'Delete',

					formButton__Create_new_element: 'Create new element',

					modalHeader__App_update_available: 'App update available',
					interfaceString__App_update_available: 'You need to download the updated app version to continue working with the system.',
					modalButton__Update: 'Get newest version',

					interfaceString__1_image: 'Image',
					interfaceString__2_image: 'Images',
					interfaceString__5_image: 'Images',

					interfaceString__queuedLIst: 'Items in queue',

					interfaceString__1_queued_item: 'item in the queue',
					interfaceString__2_queued_item: 'items in the queue',
					interfaceString__5_queued_item: 'items in the queue',

					formLabel__passwordInput: 'Password',
					formButton__Login: 'Login',
					formButton__Logout: 'Logout',

					interfaceString__selectExhibition: 'Select exhibition',

					interfaceString__noSavedFormData: 'No items in queue',

					interfaceString__loadingData: 'Loading data',
					interfaceString__syncingData: 'Syncing data',
					interfaceString__sendingData: 'Sending data',

					formButton__Check_PIN: 'Check PIN',

					formButton__Send_data_to_server: 'Send data to server',

					notificationHeader__error: 'Error',
					notificationHeader__warning: 'Warning',
					notificationHeader__success: 'Success',
					notificationHeader__info: 'Information',

					notificationDescription__checkPINFailed: 'PIN code entered incorrectly',

					notificationDescription__syncDataCompleted:'Data successfully synchronized',
					notificationDescription__syncDataError: 'Data synchronization error',

					notificationDescription__sentDataToServer: 'Data saved in the cloud',
					notificationDescription__saveDataLocally: 'Data saved on the device',
					notificationDescription__deleteStagedForm: 'Item deleted successfully'
				},
				ru: {
					formLabel__interestUCLS: 'UCLS',
					formHelpText__interestUCLS: 'Производство под собственной торговой маркой',

					formLabel__interestEVENSWISS: 'EVENSWISS',
					formHelpText__interestEVENSWISS: 'Распространение продукции бренда',

					formLabel__interestALOVE: 'ALÔVE',
					formHelpText__interestALOVE: 'Распространение продукции бренда',

					formLabel__interestSUISSETTE: 'SUISSETTE',
					formHelpText__interestSUISSETTE: 'Распространение продукции бренда',

					formLabel__companyNameInput: 'Название компании',
					formPlaceholder__companyNameInput: 'Введите название компании',

					formLabel__customerFamilyNameInput: 'Фамилия',
					formPlaceholder__customerFamilyNameInput: 'Введите Фамилию',

					formLabel__customerGivenNameInput: 'Имя',
					formPlaceholder__customerGivenNameInput: 'Введите Имя',

					formLabel__customerCountrySelect: 'Страна',

					formLabel__emailInput: 'Email адрес',
					formPlaceholder__emailInput: 'contact@domain.com',

					formLabel__websiteInput: 'Вебсайт',
					formPlaceholder__websiteInput: 'domain.com',

					formLabel__countryOfInterest: 'Интересующие страны',
					formPlaceholder__countryOfInterest: 'Начните вводить название страны',
					formHelpText__countryOfInterest: 'Выбраны страны:',

					formLabel__businessType: 'Тип бизнеса',

					formLabel__Notes:'Комментарии',
					formPlaceholder__Notes: 'Добавляйте заметки, чтобы легче было вспомнить, о чем был разговор',


					interfaceString__retailer: 'Розничный продавец',
					interfaceString__department_store: 'Универмаг',
					interfaceString__multichannel: 'Мультиканальный',
					interfaceString__distributor: 'Дистрибьютор',
					interfaceString__retailer_department_store: 'Розничный продавец или универмаг',
					interfaceString__online_platform: 'Онлайн платформа',
					interfaceString__pharma_clinics_dermatologist: 'Сеть аптек, клиника или дерматолог',
					interfaceString__beauticians: 'Косметолог',

					formLabel__otherBusinessType: 'Другой тип бизнеса',
					formPlaceholder__otherBusinessType: 'Вы можете ввести любой отсутствующий тип',

					interfaceString__Click_to_remove: 'Нажмите для удаления',
					interfaceString__Go_to_main_page: 'Перейти на главную страницу системы',
					interfaceString__Create_new_item: 'Создать новую форму',
					interfaceString__Clear_inputs: 'Очистить поля',

					formButton__Add_image: 'Добавить изображения',
					formButton__Save: 'Сохранить',
					formButton__Delete: 'Удалить',

					formButton__Create_new_element: 'Создать новый элемент',

					modalHeader__App_update_available: 'Доступно обновление приложения',
					interfaceString__App_update_available: 'Вам необходимо скачать обновленную версию программы, чтобы продолжить работу с системой.',
					modalButton__Update: 'Скачать новую версию',

					interfaceString__1_image: 'Изображение',
					interfaceString__2_image: 'Изображения',
					interfaceString__5_image: 'Изображений',

					interfaceString__queuedLIst: 'Элементы в очереди',

					interfaceString__1_queued_item: 'элемент в очереди',
					interfaceString__2_queued_item: 'элемента в очереди',
					interfaceString__5_queued_item: 'элементов в очереди',

					formLabel__passwordInput: 'Пароль',

					formButton__Login: 'Войти',
					formButton__Logout: 'Выйти',

					interfaceString__selectExhibition: 'Выберите выставку',

					interfaceString__noSavedFormData: 'Нет элементов в очереди',

					interfaceString__loadingData: 'Данные загружаются',
					interfaceString__syncingData: 'Данные синхронизируются',
					interfaceString__sendingData: 'Данные отправляются',

					formButton__Check_PIN: 'Проверить PIN',

					formButton__Send_data_to_server: 'Отправить данные на сервер',

					notificationHeader__error: 'Ошибка',
					notificationHeader__warning: 'Внимание',
					notificationHeader__success: 'Успех',
					notificationHeader__info: 'Информация',

					notificationDescription__checkPINFailed: 'PIN-код введен неправильно',

					notificationDescription__syncDataCompleted:'Данные успешно синхронизированы',
					notificationDescription__syncDataError: 'Ошибка синхронизации данных',

					notificationDescription__sentDataToServer: 'Данные сохранены в облаке',
					notificationDescription__saveDataLocally: 'Данные сохранены на устройстве',
					notificationDescription__deleteStagedForm: 'Элемент успешно удален'
				},
			}
		};
	},
	computed: {
		$interfaceLanguage: {
			get: function () {
				return this.$store.state.interfaceLanguage;
			},
			set: function (newValue) {
				this.$store.state.interfaceLanguage = newValue
				localStorage.interfaceLanguage = newValue;
			}
		}
	},
	methods: {
		$t(string_index, language = null) {
			//вернуть перевод строки или английскую версию или сам индекс!
			let ln = (language) ? language : this.$interfaceLanguage
			if (this.translation[ln] && this.translation[ln][string_index]) return this.translation[ln][string_index];
			else if (this.translation[ln] && !this.translation[ln][string_index] && this.translation.en[string_index]) {
				//console.warn('no_to: '+ln+ ' ' + string_index)
				return '*' + this.translation.en[string_index] + '*';
			} else {
				console.log('no_string: ' + string_index)
				return '*' + string_index.toString() + '*';
			}
		},
		$pluralize(count, string_1, string_2, string_5) {
			count  = Math.abs(count) % 100
			let count_1 = count % 10
			if (count > 10 && count < 20) return string_5
			if (count_1 > 1 && count_1 < 5) return string_2
			if (count_1 == 1) return string_1
			return string_5
		}
	}
}
