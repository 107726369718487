export default {
	data() {
		return {

		}
	},
	methods: {
		scrollToItem(itemRef) {
			//this.$refs[itemRef].scrollIntoView()


			const element = this.$refs[itemRef]
			const headerOffset = 75
			const elementPosition = element.getBoundingClientRect().top
			const offsetPosition = elementPosition + window.pageYOffset - headerOffset

			window.scrollTo({
				top: offsetPosition,
				behavior: "smooth"
			})
		}
	}
}
