<template>
	<div @click="updateDataUsage()" class="text-gray-400 font-light text-xs flex items-center justify-center cursor-default dark:text-blue-500">
		<svg :class="{'text-red-500':!isPersisted}" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-4 h-4">
			<path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
		</svg>
		<span v-if="dataUsage.usage">{{formatFileSize(+dataUsage.usage)}}</span>
	</div>
</template>

<script>
import {filesize} from "filesize"
export default {
	name: "storageUsage",
	computed: {
		dataUsage() {
			return this.$store.state.dataUsage
		},
		isPersisted() {
			return this.$store.state.isPersisted
		}
	},
	methods: {
		updateDataUsage() {
			//this.$store.dispatch('checkDataUsage')
		},
		formatFileSize(size) {
			return filesize(size, {base: 2, standard: "jedec"})
		},
	}
}
</script>
