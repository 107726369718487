import {createRouter, createWebHistory} from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'index',
		component: () => /* webpackChunkName: "index" */
			import('@/views/index')}
	,
	{path: '/add', name: "addForm", props: false, component: () => import(/* webpackChunkName: "form" */ "@/views/oneForm")},
	{path: '/form/:id', name: "editForm", props: true, component: () => import(/* webpackChunkName: "form" */ "@/views/oneForm"),},

	{path: '/tasks', name: "tasksBoard", props: false, component: () => import(/* webpackChunkName: "tasks" */ "@/views/tasksBoard")},

	{path: '/:catchAll(.*)', name: "errorPage", props: false, component: () => import(/* webpackChunkName: "errors" */ "@/views/errorPage")}
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

export default router
