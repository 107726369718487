<template>
	<div>
		<check-update/>
		<div v-if="PIN_OK" class="relative mx-auto p-2 antialiased font-sans dark:bg-slate-900 bg-gray-200 min-h-screen">
			<data-loading :loading="dataLoading"/>
			<div class="z-40 bg-white/75 backdrop-blur border-b border-gray-200 fixed top-0 left-0 right-0 px-2 py-2 shadow grid grid-cols-3 dark:bg-slate-900/75 dark:border-slate-700">
				<div class="flex space-x-3">
					<template v-if="$route.name === 'addForm' || $route.name === 'editForm'">
						<router-link to="/" tag="button" :title="$t('interfaceString__Go_to_main_page')"
						             class="inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-900/30 dark:text-blue-300 dark:border-blue-400/30">
							<!--						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-6 h-6">-->
							<!--							<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"/>-->
							<!--						</svg>-->
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.75" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"/>
							</svg>
						</router-link>
						<router-link to="/add" tag="button" :title="$t('interfaceString__Create_new_item')"
						             class="inline-flex items-center text-blue-800 rounded-md border border-blue-500 bg-blue-50 p-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-900/60 dark:text-blue-300 dark:border-blue-400/70">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round"
								      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"/>
							</svg>
						</router-link>
					</template>
					<template v-else>
						<div class="inline-flex items-center rounded-md border border-gray-100 bg-white p-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 dark:bg-blue-900/30 dark:text-blue-300 dark:border-blue-400/10">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-6 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"/>
							</svg>
						</div>
					</template>
				</div>
				<div class="flex items-center justify-center">
					<offline-indication/>
				</div>
				<div class="flex items-center justify-end space-x-4">
					<!-- <storage-usage/>-->
					<pin-lock/>
					<dark-mode/>
					<language-selector/>
				</div>
			</div>
			
			<div class="mt-4 mb-9">
				<router-view></router-view>
				<div class="text-gray-500 text-xs select-none text-center">
					{{ AppVersion }}
				</div>
			</div>
			
			<!-- <notification-window/>-->
		</div>
		<pin-form v-else/>
		<notification-area/>
	</div>
</template>

<script>
import {mapState} from 'vuex'
import api from "@/api"

import offlineIndication from '@/components/offlineIndication'
import checkUpdate from "@/components/checkUpdate"
import pinForm from "@/components/pinForm"
import pinLock from "@/components/pinLock"
import languageSelector from "@/components/languageSelector"
import storageUsage from "@/components/storageUsage"
import darkMode from "@/components/darkMode"
import dataLoading from "@/components/dataLoading"
import notificationArea from "@/components/notificationArea"
import _ from "lodash";


export default {
	name: 'App',
	components: {
		notificationArea,
		pinForm,
		pinLock,
		darkMode,
		checkUpdate,
		offlineIndication,
		languageSelector,
		storageUsage,
		dataLoading
	},
	data() {
		return {
			dataLoading: false
		}
	},
	methods: {
		checkToken() {
			// If token is in local storage
			this.dataLoading = true
			if (localStorage.apiToken) {
				api().post('token', {'token': localStorage.apiToken}).then((response) => {
					// console.log('=======')
					// console.log(response.status)
					// console.warn(response.data.apiToken)
					// console.table(response.data.userData)
					// console.log('=======')
					if (response.data.apiToken && response.data.userData) {
						this.$store.dispatch('setApiToken', response.data.apiToken)
						this.$store.dispatch('setUserData', response.data.userData)
					}
					this.dataLoading = false
				}).catch(e => {
					//TODO: HANDLE ERRORS?
					this.$store.dispatch('setApiToken', null)
					this.$store.dispatch('setUserData', [])
				}).finally(() => this.dataLoading = false)
			} else {
				this.$store.dispatch('setApiToken', null)
				this.$store.dispatch('setUserData', [])
				this.dataLoading = false
			}
			// Check it
			// Login user
			
			// this.$store.dispatch('setApiToken',response.data.apiToken)
			// this.$store.dispatch('setUserData',response.data.userData)
		}
	},
	computed: {
		...mapState([
			'AppVersion',
			'isOnline',
			'forceOffline',
			'stagedFormsData',
			'hasNewerVersion',
			'presentedPIN',
			'PIN'
		]),
		PIN_OK() {
			return (this.presentedPIN && this.PIN && (+this.presentedPIN === +this.PIN))
		},
		loggedIn() {
			return this.$store.state.apiToken && this.$store.state.userData
		},
	},
	async mounted() {
		document.title = 'UT GROUP FORMS'
		
		window.addEventListener('offline', () => {
			// console.log('OFFLINE')
			this.$store.commit('updateOnlineStatus', false)
		})
		window.addEventListener('online', () => {
			// console.log('ONLINE')
			//TODO: Не работает force offline
			// if (!this.$store.forceOffline) {
			this.$store.commit('updateOnlineStatus', true)
			if (this.loggedIn) this.$store.dispatch('syncData', {silent: true, redirect: ''})
			// }
		})
	},
	async created() {
		if (this.isOnline) this.checkToken()
		
		if (localStorage.PIN) this.$store.dispatch('checkPIN', localStorage.PIN)
		if (+localStorage.isDarkMode) this.$store.dispatch('toggleDarkMode', true)
		
		let navigatorInterfaceLanguage = (navigator.language) ? navigator.language.slice(0, 2).toLowerCase() : ''
		this.$interfaceLanguage = (localStorage.interfaceLanguage && Boolean(this.$store.state.locales.find(element => element.code === localStorage.interfaceLanguage))) ? localStorage.interfaceLanguage : ((navigatorInterfaceLanguage && Boolean(this.$store.state.locales.find(element => element.code === navigatorInterfaceLanguage))) ? navigatorInterfaceLanguage : this.$store.state.fallbackLocale)
		this.$store.state.exhibitionSelected = (localStorage.exhibitionSelected && this.$store.state.exhibitionList[localStorage.exhibitionSelected]) ? localStorage.exhibitionSelected : this.$store.state.exhibitionDefault
		
		if (navigator.storage && navigator.storage.persist) {
			const isPersisted = await navigator.storage.persist()
			this.$store.state.isPersisted = isPersisted
			// console.log(`Persisted storage granted: ${isPersisted}`)
		}
	},
	beforeUnmount() {
	}
}
</script>
