<template>
	<!-- PIN FORM -->
	<div class="fixed bg-gray-50 text-gray-500 top-0 bottom-0 right-0 left-0 flex flex-col justify-center content-center dark:bg-slate-900 dark:text-white overflow-hidden touch-none">
		<div class="w-50 mx-auto space-y-4">
			<form autocomplete="off" class="flex justify-center space-x-2">
				<input type="text" autocomplete="off" inputmode="numeric" maxlength="1" v-model.number="pin1" ref="pin1" @input="goRef('pin2')" @keyup.delete="goRef('pin1')" size="1"
				       class="p-4 text-2xl font-semibold w-12 text-center rounded-md placeholder:text-gray-300 border border-gray-400 dark:bg-slate-800 dark:border-blue-500">
				<input type="text" autocomplete="off" inputmode="numeric" maxlength="1" v-model.number="pin2" ref="pin2" @input="goRef('pin3')" @keyup.delete="goRef('pin1')" size="1"
				       class="p-4 text-2xl font-semibold w-12 text-center rounded-md placeholder:text-gray-300 border border-gray-400 dark:bg-slate-800 dark:border-blue-500">
				<input type="text" autocomplete="off" inputmode="numeric" maxlength="1" v-model.number="pin3" ref="pin3" @input="goRef('pin4')" @keyup.delete="goRef('pin2')" size="1"
				       class="p-4 text-2xl font-semibold w-12 text-center rounded-md placeholder:text-gray-300 border border-gray-400 dark:bg-slate-800 dark:border-blue-500">
				
				<input type="text" autocomplete="off" inputmode="numeric" maxlength="1" v-model.number="pin4" ref="pin4" @input="goRef('pin5')" @keyup.delete="goRef('pin3')" size="1"
				       class="p-4 text-2xl font-semibold w-12 text-center rounded-md placeholder:text-gray-300 border border-gray-400 dark:bg-slate-800 dark:border-blue-500">
				<input type="text" autocomplete="off" inputmode="numeric" maxlength="1" v-model.number="pin5" ref="pin5" @input="goRef('pin6')" @keyup.delete="goRef('pin4')" size="1"
				       class="p-4 text-2xl font-semibold w-12 text-center rounded-md placeholder:text-gray-300 border border-gray-400 dark:bg-slate-800 dark:border-blue-500">
				<input type="text" autocomplete="off" inputmode="numeric" maxlength="1" v-model.number="pin6" ref="pin6" @input="checkPIN()" @keyup.delete="goRef('pin5')" size="1"
				       class="p-4 text-2xl font-semibold w-12 text-center rounded-md placeholder:text-gray-300 border border-gray-400 dark:bg-slate-800 dark:border-blue-500">
			</form>
			<button @click.prevent="checkPIN()"
			        class="flex w-full justify-center rounded-md border border-transparent bg-blue-50 p-3 text-sm font-medium text-blue-700 shadow-sm border border-blue-700 focus:outline-none focus:ring-2 focus:ring-green-500 dark:focus:ring-blue-500 focus:ring-offset-2 dark:bg-blue-900/30 dark:text-white dark:border-blue-500">
				{{ $t('formButton__Check_PIN') }}
			</button>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex"

export default {
	name: "pinForm",
	data() {
		return {
			pin1: null,
			pin2: null,
			pin3: null,
			pin4: null,
			pin5: null,
			pin6: null,
		}
	},
	components: {},
	computed: {},
	methods: {
		goRef(ref_id) {
			this[ref_id] = null
			this.$refs[ref_id].value = null
			this.$refs[ref_id].focus()
		},
		checkPIN() {
			let enteredPIN = this.pin1.toString() + this.pin2.toString() + this.pin3.toString() + this.pin4.toString() + this.pin5.toString() + this.pin6.toString()
			this.$store.dispatch('checkPIN', enteredPIN)
		}
	},
	mounted() {
		this.$refs.pin1.focus()
	}
}
</script>
