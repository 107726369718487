import {createApp} from 'vue'

import App from './App.vue'
import './registerServiceWorker'
import './App.css'

const app = createApp(App)

import translateMixin from "./mixins/translateMixin"
app.mixin(translateMixin)

import interfaceHelpersMixin from "./mixins/interfaceHelpersMixin"
app.mixin(interfaceHelpersMixin)

import validateMixin from "./mixins/validateMixin"
app.mixin(validateMixin)

import store from "@/store"
app.use(store)

import router from "@/router"
app.use(router)

// import componentName from '@/components/componentName'
// app.component('componentName', componentName)

import api from './api'

app.mount('#app')

store.dispatch('init').then(r => {})
