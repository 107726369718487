<template>
	<div v-if="loading" :class="fullwidth ? 'fixed' : 'absolute rounded-md'" class="left-0 top-0 right-0 bottom-0 z-30 bg-gray-50 text-gray-600 opacity-80 dark:text-white dark:bg-slate-900/90 grid content-center">
		<div :class="fullwidth ? '' : 'rounded-md'" class="p-4 flex flex-col">
			<div class="text-center mx-auto">
				<svg xmlns="http://www.w3.org/2000/svg" class="mb-5 h-14 w-14" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
					<circle cx="50" cy="50" fill="none" stroke="currentColor" stroke-width="1" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
						<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.6896551724137931s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
					</circle>
				</svg>
			</div>
			<div class="text-center animate-pulse">{{ (label) ? label : $t('interfaceString__loadingData') }}...</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "dataLoading",
	props: {
		loading: Boolean,
		fullwidth: {
			type: Boolean,
			default: true
		},
		label: {
			type: String,
			default: null
		}
	},
	computed: {},
	methods: {}
}
</script>

<style scoped>
</style>
