<template>
	<div class="relative inline-block text-left">
		<div @click="open=!open">
			<button type="button" class="inline-flex justify-center rounded-md border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:border-blue-500 dark:bg-blue-900/30 dark:text-blue-300 dark:border-blue-400/30" id="menu-button" aria-expanded="true" aria-haspopup="true">
				<svg class="h-6 w-6 text-gray-500 dark:text-blue-500/90" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round"
						      d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"/>
					</svg>
				<svg class="-mr-1 ml-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
					<path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
				</svg>
			</button>
		</div>
		
		<!--
		  Dropdown menu, show/hide based on menu state.
	  
		  Entering: "transition ease-out duration-100"
			From: "transform opacity-0 scale-95"
			To: "transform opacity-100 scale-100"
		  Leaving: "transition ease-in duration-75"
			From: "transform opacity-100 scale-100"
			To: "transform opacity-0 scale-95"
		-->
		<div v-if="open" class="absolute right-0 z-10 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-blue-500 focus:outline-none dark:bg-slate-900 dark:text-white dark:border-blue-400/30" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
			<div class="py-1" role="none">
				<div v-for="locale in locales" @click="$interfaceLanguage = locale.code; open = false" :class="{'bg-blue-50 text-gray-900 dark:text-gray-50 dark:bg-slate-700':$interfaceLanguage == locale.code}" class="text-gray-700 dark:text-white block px-4 py-2 text-sm cursor-pointer">{{locale.name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";

export default {
	name: "languageSelector",
	data() {
		return {
			open: false
		}
	},
	computed: {
		...mapState([
			'locales'
		])
	},
}
</script>

<style scoped>

</style>
