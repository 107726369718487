<template>
	<div class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-if="updateExists">
		<!--
		  Background backdrop, show/hide based on modal state.
	  
		  Entering: "ease-out duration-300"
			From: "opacity-0"
			To: "opacity-100"
		  Leaving: "ease-in duration-200"
			From: "opacity-100"
			To: "opacity-0"
		-->
		<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
		
		<div class="fixed inset-0 z-20 overflow-y-auto">
			<div class="flex min-h-full items-end justify-center py-4 px-8 text-center">
				<!--
				  Modal panel, show/hide based on modal state.
		  
				  Entering: "ease-out duration-300"
					From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					To: "opacity-100 translate-y-0 sm:scale-100"
				  Leaving: "ease-in duration-200"
					From: "opacity-100 translate-y-0 sm:scale-100"
					To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				-->
				<div class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all m-8 max-w-lg p-6 min-w-full dark:bg-slate-700 dark:text:white">
					<div class="sm:flex sm:items-start">
						<div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10 dark:bg-red-500">
							<!-- Heroicon name: outline/exclamation-triangle -->
							<svg class="h-6 w-6 text-red-600 dark:text-white -mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z" />
							</svg>
						</div>
						<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
							<h3 class="text-lg font-medium leading-6 text-gray-900 dark:text-white" id="modal-title">{{ $t('modalHeader__App_update_available') }}</h3>
							<div class="mt-2">
								<p class="text-sm text-gray-500 dark:text-white/80">{{$t('interfaceString__App_update_available')}}</p>
							</div>
						</div>
					</div>
					<div class="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
						<button type="button" class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto sm:text-sm" @click="refreshApp">{{ $t('modalButton__Update') }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import appUpdateMixin from '@/mixins/appUpdateMixin'
export default {
	name: "checkUpdate",
	mixins: [appUpdateMixin],
	computed: {
		hasNewerVersion() {
			return this.updateExists
		}
	}
}
</script>

<style scoped>

</style>
