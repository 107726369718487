<template>
	<div @click.prevent="toggleOffline()">
		<template v-if="isOnline">
			<svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-green-600 dark:text-green-500" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round"
				      d="M9.348 14.651a3.75 3.75 0 010-5.303m5.304 0a3.75 3.75 0 010 5.303m-7.425 2.122a6.75 6.75 0 010-9.546m9.546 0a6.75 6.75 0 010 9.546M5.106 18.894c-3.808-3.808-3.808-9.98 0-13.789m13.788 0c3.808 3.808 3.808 9.981 0 13.79M12 12h.008v.007H12V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"/>
			</svg>
		</template>
		<template v-else>
			<svg xmlns="http://www.w3.org/2000/svg" class="w-8 h-8 text-red-500 dark:text-red-400 animate-pulse" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round"
				      d="M3 3l8.735 8.735m0 0a.374.374 0 11.53.53m-.53-.53l.53.53m0 0L21 21M14.652 9.348a3.75 3.75 0 010 5.304m2.121-7.425a6.75 6.75 0 010 9.546m2.121-11.667c3.808 3.807 3.808 9.98 0 13.788m-9.546-4.242a3.733 3.733 0 01-1.06-2.122m-1.061 4.243a6.75 6.75 0 01-1.625-6.929m-.496 9.05c-3.068-3.067-3.664-7.67-1.79-11.334M12 12h.008v.008H12V12z"/>
			</svg>
		</template>
	</div>
	
	
	<!--	<div v-if="!isOnline" class="fixed top-0 left-0 right-0 opacity-75 z-40 w-full text-center py-2 bg-red-300 border-b border-red-700 text-white">-->
	<!--		Offline mode-->
	<!--	</div>-->
</template>

<script>
export default {
	name: "offlineIndication",
	computed: {
		isOnline() {
			return this.$store.state.isOnline
		},
		forceOffline() {
			return this.$store.state.forceOffline
		}
	},
	methods: {
		toggleOffline() {
			// this.$store.commit('updateOnlineStatus', !this.isOnline)
			// this.$store.commit('updateForceOfflineStatus', !this.isOnline)
		},
	}
}
</script>

<style scoped>

</style>
