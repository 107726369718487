import {version} from '../../package.json'
import {createStore} from 'vuex'
import router from "@/router"
import api from "@/api"

const store = createStore({
	state() {
		return {
			AppVersion: version,

			interfaceLanguage: 'en',
			fallbackLocale: 'en',
			locales: [
				{
					name: 'English',
					code: 'en',
					iso: 'en-US'
				},
				{
					name: 'Русский',
					code: 'ru',
					iso: 'ru-RU'
				}
			],

			isDarkMode: false,

			userData: {},
			apiToken: null,

			presentedPIN: null,
			PIN: 916340,

			database: null,
			databaseName: 'forms',
			databaseObjectStoreName: 'formsData',
			keyPath: 'formIndex',
			isOnline: navigator.onLine,
			forceOffline: false,

			dataLoading: false,
			dataSaving: false,

			hasNewerVersion: false,
			isPersisted: false,

			activeFormIndex: null,
			activeFormData: {},

			fromDefaults: {
				formIndex: null,

				exhibition: null,

				interestEVENSWISS: false,
				interestUCLS: false,
				interestALOVE: false,
				interestSUISSETTE: false,

				companyName: null,

				givenName: null,
				familyName: null,

				email: null,
				country: 0,

				website: null,
				notes: null,

				createdAt: null,
				updatedAt: null,

				queued: true,
				paused: false,

				countriesSelected: [],
				filesData: [],
				customerBusinessTypes: [],

				otherTypeOfBusiness: null
			},

			stagedFormsData: {},

			customerBusinessTypesData: [
				'distributor',
				'retailer_department_store',
				'online_platform',
				'pharma_clinics_dermatologist',
				'beauticians',
			],

			countriesData: {
				"1": {
					"id": "1",
					"title": "Afghanistan",
					"iso": "AF"
				},
				"2": {
					"id": "2",
					"title": "Albania",
					"iso": "AL"
				},
				"3": {
					"id": "3",
					"title": "Algeria",
					"iso": "DZ"
				},
				"4": {
					"id": "4",
					"title": "Andorra",
					"iso": "AD"
				},
				"5": {
					"id": "5",
					"title": "Angola",
					"iso": "AO"
				},
				"6": {
					"id": "6",
					"title": "Anguilla",
					"iso": "AI"
				},
				"7": {
					"id": "7",
					"title": "Antigua and Barbuda",
					"iso": "AG"
				},
				"8": {
					"id": "8",
					"title": "Argentina",
					"iso": "AR"
				},
				"9": {
					"id": "9",
					"title": "Armenia",
					"iso": "AM"
				},
				"10": {
					"id": "10",
					"title": "Aruba",
					"iso": "AW"
				},
				"11": {
					"id": "11",
					"title": "Ascension Island",
					"iso": "AC"
				},
				"12": {
					"id": "12",
					"title": "Australia",
					"iso": "AU"
				},
				"13": {
					"id": "13",
					"title": "Austria",
					"iso": "AT"
				},
				"14": {
					"id": "14",
					"title": "Azerbaijan",
					"iso": "AZ"
				},
				"15": {
					"id": "15",
					"title": "Bahamas",
					"iso": "BS"
				},
				"16": {
					"id": "16",
					"title": "Bahrain",
					"iso": "BH"
				},
				"17": {
					"id": "17",
					"title": "Bangladesh",
					"iso": "BD"
				},
				"18": {
					"id": "18",
					"title": "Barbados",
					"iso": "BB"
				},
				"19": {
					"id": "19",
					"title": "Belarus",
					"iso": "BY"
				},
				"20": {
					"id": "20",
					"title": "Belgium",
					"iso": "BE"
				},
				"21": {
					"id": "21",
					"title": "Belize",
					"iso": "BZ"
				},
				"22": {
					"id": "22",
					"title": "Benin",
					"iso": "BJ"
				},
				"23": {
					"id": "23",
					"title": "Bermuda",
					"iso": "BM"
				},
				"24": {
					"id": "24",
					"title": "Bhutan",
					"iso": "BT"
				},
				"25": {
					"id": "25",
					"title": "Bolivia",
					"iso": "BO"
				},
				"26": {
					"id": "26",
					"title": "Bosnia and Herzegovina",
					"iso": "BA"
				},
				"27": {
					"id": "27",
					"title": "Botswana",
					"iso": "BW"
				},
				"28": {
					"id": "28",
					"title": "Brazil",
					"iso": "BR"
				},
				"29": {
					"id": "29",
					"title": "Brunei",
					"iso": "BN"
				},
				"30": {
					"id": "30",
					"title": "Bulgaria",
					"iso": "BG"
				},
				"31": {
					"id": "31",
					"title": "Burkina Faso",
					"iso": "BF"
				},
				"32": {
					"id": "32",
					"title": "Burundi",
					"iso": "BI"
				},
				"33": {
					"id": "33",
					"title": "Cambodia",
					"iso": "KH"
				},
				"34": {
					"id": "34",
					"title": "Cameroon",
					"iso": "CM"
				},
				"35": {
					"id": "35",
					"title": "Canada",
					"iso": "CA"
				},
				"36": {
					"id": "36",
					"title": "Cape Verde",
					"iso": "CV"
				},
				"37": {
					"id": "37",
					"title": "Cayman Islands",
					"iso": "KY"
				},
				"38": {
					"id": "38",
					"title": "Central African Republic",
					"iso": "CF"
				},
				"39": {
					"id": "39",
					"title": "Chad",
					"iso": "TD"
				},
				"40": {
					"id": "40",
					"title": "Chile",
					"iso": "CL"
				},
				"41": {
					"id": "41",
					"title": "China (People’s Republic of)",
					"iso": "CN"
				},
				"42": {
					"id": "42",
					"title": "Cocos (keeling) Islands",
					"iso": "CC"
				},
				"43": {
					"id": "43",
					"title": "Christmas Island",
					"iso": "CX"
				},
				"44": {
					"id": "44",
					"title": "Colombia",
					"iso": "CO"
				},
				"45": {
					"id": "45",
					"title": "Comoros",
					"iso": "KM"
				},
				"46": {
					"id": "46",
					"title": "Congo, Democratic Republic",
					"iso": "CD"
				},
				"47": {
					"id": "47",
					"title": "Congo, Republic",
					"iso": "CG"
				},
				"48": {
					"id": "48",
					"title": "Cook Islands",
					"iso": "CK"
				},
				"49": {
					"id": "49",
					"title": "Costa Rica",
					"iso": "CR"
				},
				"50": {
					"id": "50",
					"title": "Cote d'Ivoire",
					"iso": "CI"
				},
				"51": {
					"id": "51",
					"title": "Croatia",
					"iso": "HR"
				},
				"52": {
					"id": "52",
					"title": "Cuba",
					"iso": "CU"
				},
				"53": {
					"id": "53",
					"title": "Curacao",
					"iso": "CW"
				},
				"54": {
					"id": "54",
					"title": "Cyprus",
					"iso": "CY"
				},
				"55": {
					"id": "55",
					"title": "Czech Republic",
					"iso": "CZ"
				},
				"56": {
					"id": "56",
					"title": "Denmark",
					"iso": "DK"
				},
				"57": {
					"id": "57",
					"title": "Djibouti",
					"iso": "DJ"
				},
				"58": {
					"id": "58",
					"title": "Dominica",
					"iso": "DM"
				},
				"59": {
					"id": "59",
					"title": "Dominican Republic",
					"iso": "DO"
				},
				"60": {
					"id": "60",
					"title": "Ecuador",
					"iso": "EC"
				},
				"61": {
					"id": "61",
					"title": "Egypt",
					"iso": "EG"
				},
				"62": {
					"id": "62",
					"title": "El Salvador",
					"iso": "SV"
				},
				"63": {
					"id": "63",
					"title": "Equatorial Guinea",
					"iso": "GQ"
				},
				"64": {
					"id": "64",
					"title": "Eritrea",
					"iso": "ER"
				},
				"65": {
					"id": "65",
					"title": "Estonia",
					"iso": "EE"
				},
				"66": {
					"id": "66",
					"title": "Etiopia",
					"iso": "ET"
				},
				"67": {
					"id": "67",
					"title": "Falkland Island",
					"iso": "FK"
				},
				"68": {
					"id": "68",
					"title": "Faroe Islands",
					"iso": "FO"
				},
				"69": {
					"id": "69",
					"title": "Fiji",
					"iso": "FJ"
				},
				"70": {
					"id": "70",
					"title": "Finland",
					"iso": "FI"
				},
				"71": {
					"id": "71",
					"title": "France",
					"iso": "FR"
				},
				"72": {
					"id": "72",
					"title": "French Antarctica",
					"iso": "TF"
				},
				"73": {
					"id": "73",
					"title": "French Guiana",
					"iso": "GF"
				},
				"74": {
					"id": "74",
					"title": "French Polynesia",
					"iso": "PF"
				},
				"75": {
					"id": "75",
					"title": "Gabon",
					"iso": "GA"
				},
				"76": {
					"id": "76",
					"title": "Gambia",
					"iso": "GM"
				},
				"77": {
					"id": "77",
					"title": "Georgia, Republic",
					"iso": "GE"
				},
				"78": {
					"id": "78",
					"title": "Germany",
					"iso": "DE"
				},
				"79": {
					"id": "79",
					"title": "Ghana",
					"iso": "GH"
				},
				"80": {
					"id": "80",
					"title": "Gibraltar",
					"iso": "GI"
				},
				"81": {
					"id": "81",
					"title": "Great Britain and Northern Ireland",
					"iso": "GB"
				},
				"82": {
					"id": "82",
					"title": "Greece",
					"iso": "GR"
				},
				"83": {
					"id": "83",
					"title": "Greenland",
					"iso": "GL"
				},
				"84": {
					"id": "84",
					"title": "Grenada",
					"iso": "GD"
				},
				"85": {
					"id": "85",
					"title": "Guadaloupe",
					"iso": "GP"
				},
				"86": {
					"id": "86",
					"title": "Guam",
					"iso": "GU"
				},
				"87": {
					"id": "87",
					"title": "Guatemala",
					"iso": "GT"
				},
				"88": {
					"id": "88",
					"title": "Guernsey",
					"iso": "GG"
				},
				"89": {
					"id": "89",
					"title": "Guinea,Republic",
					"iso": "GN"
				},
				"90": {
					"id": "90",
					"title": "Guinea-Bissau",
					"iso": "GW"
				},
				"91": {
					"id": "91",
					"title": "Guyana",
					"iso": "GY"
				},
				"92": {
					"id": "92",
					"title": "Haiti",
					"iso": "HT"
				},
				"93": {
					"id": "93",
					"title": "Honduras",
					"iso": "HN"
				},
				"94": {
					"id": "94",
					"title": "Hong Kong",
					"iso": "HK"
				},
				"95": {
					"id": "95",
					"title": "Hungary",
					"iso": "HU"
				},
				"96": {
					"id": "96",
					"title": "Iceland",
					"iso": "IS"
				},
				"97": {
					"id": "97",
					"title": "India",
					"iso": "IN"
				},
				"98": {
					"id": "98",
					"title": "Indonesia",
					"iso": "ID"
				},
				"99": {
					"id": "99",
					"title": "Iran",
					"iso": "IR"
				},
				"100": {
					"id": "100",
					"title": "Iraq",
					"iso": "IQ"
				},
				"101": {
					"id": "101",
					"title": "Ireland",
					"iso": "IE"
				},
				"102": {
					"id": "102",
					"title": "Isle of Man",
					"iso": "IM"
				},
				"103": {
					"id": "103",
					"title": "Israel",
					"iso": "IL"
				},
				"104": {
					"id": "104",
					"title": "Italy",
					"iso": "IT"
				},
				"105": {
					"id": "105",
					"title": "Jamaica",
					"iso": "JM"
				},
				"106": {
					"id": "106",
					"title": "Jersey",
					"iso": "JE"
				},
				"107": {
					"id": "107",
					"title": "Japan",
					"iso": "JP"
				},
				"108": {
					"id": "108",
					"title": "Jordan",
					"iso": "JO"
				},
				"109": {
					"id": "109",
					"title": "Kazakstan",
					"iso": "KZ"
				},
				"110": {
					"id": "110",
					"title": "Kenya",
					"iso": "KE"
				},
				"111": {
					"id": "111",
					"title": "Kiribati",
					"iso": "KI"
				},
				"112": {
					"id": "112",
					"title": "Korea, Dem. People’s Republic of",
					"iso": "KP"
				},
				"113": {
					"id": "113",
					"title": "South Korea",
					"iso": "KR"
				},
				"114": {
					"id": "114",
					"title": "Kosovo (UN Interim Administration)",
					"iso": "XK"
				},
				"115": {
					"id": "115",
					"title": "Kuwait",
					"iso": "KW"
				},
				"116": {
					"id": "116",
					"title": "Kyzgyzstan",
					"iso": "KG"
				},
				"117": {
					"id": "117",
					"title": "Laos",
					"iso": "LA"
				},
				"118": {
					"id": "118",
					"title": "Latvia",
					"iso": "LV"
				},
				"119": {
					"id": "119",
					"title": "Lebanon",
					"iso": "LB"
				},
				"120": {
					"id": "120",
					"title": "Lesotho",
					"iso": "LS"
				},
				"121": {
					"id": "121",
					"title": "Liberia",
					"iso": "LR"
				},
				"122": {
					"id": "122",
					"title": "Libyan",
					"iso": "LY"
				},
				"123": {
					"id": "123",
					"title": "Lithuania",
					"iso": "LT"
				},
				"124": {
					"id": "124",
					"title": "Luxembourg",
					"iso": "LU"
				},
				"125": {
					"id": "125",
					"title": "Macao",
					"iso": "MO"
				},
				"126": {
					"id": "126",
					"title": "Macedonia",
					"iso": "MK"
				},
				"127": {
					"id": "127",
					"title": "Madagascar",
					"iso": "MG"
				},
				"128": {
					"id": "128",
					"title": "Malawi",
					"iso": "MW"
				},
				"129": {
					"id": "129",
					"title": "Malaysia",
					"iso": "MY"
				},
				"130": {
					"id": "130",
					"title": "Maldives",
					"iso": "MV"
				},
				"131": {
					"id": "131",
					"title": "Mali",
					"iso": "ML"
				},
				"132": {
					"id": "132",
					"title": "Malta",
					"iso": "MT"
				},
				"133": {
					"id": "133",
					"title": "Mariana Islands",
					"iso": "MP"
				},
				"134": {
					"id": "134",
					"title": "Marshall Islands",
					"iso": "MH"
				},
				"135": {
					"id": "135",
					"title": "Martinique",
					"iso": "MQ"
				},
				"136": {
					"id": "136",
					"title": "Mauritania",
					"iso": "MR"
				},
				"137": {
					"id": "137",
					"title": "Mauritius",
					"iso": "MU"
				},
				"138": {
					"id": "138",
					"title": "Mayotte",
					"iso": "YT"
				},
				"139": {
					"id": "139",
					"title": "Mexico",
					"iso": "MX"
				},
				"140": {
					"id": "140",
					"title": "Micronesia (Federated States of)",
					"iso": "FM"
				},
				"141": {
					"id": "141",
					"title": "Moldava",
					"iso": "MD"
				},
				"142": {
					"id": "142",
					"title": "Monaco",
					"iso": "MC"
				},
				"143": {
					"id": "143",
					"title": "Mongolia",
					"iso": "MN"
				},
				"144": {
					"id": "144",
					"title": "Montenegro, Republic",
					"iso": "ME"
				},
				"145": {
					"id": "145",
					"title": "Montserrat",
					"iso": "MS"
				},
				"146": {
					"id": "146",
					"title": "Morocco",
					"iso": "MA"
				},
				"147": {
					"id": "147",
					"title": "Mozambique",
					"iso": "MZ"
				},
				"148": {
					"id": "148",
					"title": "Myanmar (Union)",
					"iso": "MM"
				},
				"149": {
					"id": "149",
					"title": "Namibia",
					"iso": "NA"
				},
				"150": {
					"id": "150",
					"title": "Nauru",
					"iso": "NR"
				},
				"151": {
					"id": "151",
					"title": "Nepal",
					"iso": "NP"
				},
				"152": {
					"id": "152",
					"title": "Netherlands",
					"iso": "NL"
				},
				"153": {
					"id": "153",
					"title": "New Caledonia",
					"iso": "NC"
				},
				"154": {
					"id": "154",
					"title": "New Zealand",
					"iso": "NZ"
				},
				"155": {
					"id": "155",
					"title": "Nicaragua",
					"iso": "NI"
				},
				"156": {
					"id": "156",
					"title": "Niger",
					"iso": "NE"
				},
				"157": {
					"id": "157",
					"title": "Nigeria",
					"iso": "NG"
				},
				"158": {
					"id": "158",
					"title": "Norfolk Island",
					"iso": "NF"
				},
				"159": {
					"id": "159",
					"title": "Norway",
					"iso": "NO"
				},
				"160": {
					"id": "160",
					"title": "Oman",
					"iso": "OM"
				},
				"161": {
					"id": "161",
					"title": "Pakistan",
					"iso": "PK"
				},
				"162": {
					"id": "162",
					"title": "Palau",
					"iso": "PW"
				},
				"163": {
					"id": "163",
					"title": "Palestine",
					"iso": "PS"
				},
				"164": {
					"id": "164",
					"title": "Panama",
					"iso": "PA"
				},
				"165": {
					"id": "165",
					"title": "Papua New Guinea",
					"iso": "PG"
				},
				"166": {
					"id": "166",
					"title": "Paraguay",
					"iso": "PY"
				},
				"167": {
					"id": "167",
					"title": "Peru",
					"iso": "PE"
				},
				"168": {
					"id": "168",
					"title": "Philippines",
					"iso": "PH"
				},
				"169": {
					"id": "169",
					"title": "Pitcairn",
					"iso": "PN"
				},
				"170": {
					"id": "170",
					"title": "Poland",
					"iso": "PL"
				},
				"171": {
					"id": "171",
					"title": "Portugal",
					"iso": "PT"
				},
				"172": {
					"id": "172",
					"title": "Puerto Rico",
					"iso": "PR"
				},
				"173": {
					"id": "173",
					"title": "Qatar",
					"iso": "QA"
				},
				"174": {
					"id": "174",
					"title": "Reunion",
					"iso": "RE"
				},
				"175": {
					"id": "175",
					"title": "Romania",
					"iso": "RO"
				},
				"176": {
					"id": "176",
					"title": "Russian Federation",
					"iso": "RU"
				},
				"177": {
					"id": "177",
					"title": "Rwanda",
					"iso": "RW"
				},
				"178": {
					"id": "178",
					"title": "Saint Barthelemy",
					"iso": "BL"
				},
				"179": {
					"id": "179",
					"title": "Saint Christopher and Nevis",
					"iso": "KN"
				},
				"180": {
					"id": "180",
					"title": "Saint Helena",
					"iso": "SH"
				},
				"181": {
					"id": "181",
					"title": "Saint Lucia",
					"iso": "LC"
				},
				"182": {
					"id": "182",
					"title": "Saint Maarten",
					"iso": "SX"
				},
				"183": {
					"id": "183",
					"title": "Saint Martin",
					"iso": "MF"
				},
				"184": {
					"id": "184",
					"title": "Saint-Pierre and Miquelon",
					"iso": "PM"
				},
				"185": {
					"id": "185",
					"title": "Saint Vincent and the Grenadines",
					"iso": "VC"
				},
				"186": {
					"id": "186",
					"title": "Samoa, American",
					"iso": "AS"
				},
				"187": {
					"id": "187",
					"title": "Samoa, West",
					"iso": "WS"
				},
				"188": {
					"id": "188",
					"title": "San Marino",
					"iso": "SM"
				},
				"189": {
					"id": "189",
					"title": "Sao Tome and Principe",
					"iso": "ST"
				},
				"190": {
					"id": "190",
					"title": "Saudi Arabia",
					"iso": "SA"
				},
				"191": {
					"id": "191",
					"title": "Senegal",
					"iso": "SN"
				},
				"192": {
					"id": "192",
					"title": "Serbia Republic",
					"iso": "RS"
				},
				"193": {
					"id": "193",
					"title": "Seychelles",
					"iso": "SC"
				},
				"194": {
					"id": "194",
					"title": "Sierra Leone",
					"iso": "SL"
				},
				"195": {
					"id": "195",
					"title": "Singapore",
					"iso": "SG"
				},
				"196": {
					"id": "196",
					"title": "Slovakia, Republic of",
					"iso": "SK"
				},
				"197": {
					"id": "197",
					"title": "Slovenia",
					"iso": "SI"
				},
				"198": {
					"id": "198",
					"title": "Solomon Islands",
					"iso": "SB"
				},
				"199": {
					"id": "199",
					"title": "Somalia",
					"iso": "SO"
				},
				"200": {
					"id": "200",
					"title": "South Africa",
					"iso": "ZA"
				},
				"201": {
					"id": "201",
					"title": "South Georgia and the south Sandw. Isl.",
					"iso": "GS"
				},
				"202": {
					"id": "202",
					"title": "South Sudan",
					"iso": "SS"
				},
				"203": {
					"id": "203",
					"title": "Spain",
					"iso": "ES"
				},
				"204": {
					"id": "204",
					"title": "Sri Lanka",
					"iso": "LK"
				},
				"205": {
					"id": "205",
					"title": "Sudan",
					"iso": "SD"
				},
				"206": {
					"id": "206",
					"title": "Suriname",
					"iso": "SR"
				},
				"207": {
					"id": "207",
					"title": "Swaziland",
					"iso": "SZ"
				},
				"208": {
					"id": "208",
					"title": "Sweden",
					"iso": "SE"
				},
				"209": {
					"id": "209",
					"title": "Syria",
					"iso": "SY"
				},
				"210": {
					"id": "210",
					"title": "Taiwan (Chinese Taipei)",
					"iso": "TW"
				},
				"211": {
					"id": "211",
					"title": "Tajikistan",
					"iso": "TJ"
				},
				"212": {
					"id": "212",
					"title": "Tanzania",
					"iso": "TZ"
				},
				"213": {
					"id": "213",
					"title": "Thailand",
					"iso": "TH"
				},
				"214": {
					"id": "214",
					"title": "Timor-Leste",
					"iso": "TL"
				},
				"215": {
					"id": "215",
					"title": "Togo",
					"iso": "TG"
				},
				"216": {
					"id": "216",
					"title": "Tokelau Islands",
					"iso": "TK"
				},
				"217": {
					"id": "217",
					"title": "Tonga",
					"iso": "TO"
				},
				"218": {
					"id": "218",
					"title": "Trinidad and Tobago",
					"iso": "TT"
				},
				"219": {
					"id": "219",
					"title": "Tristan da Cunha",
					"iso": "TA"
				},
				"220": {
					"id": "220",
					"title": "Tunisia",
					"iso": "TN"
				},
				"221": {
					"id": "221",
					"title": "Turkey",
					"iso": "TR"
				},
				"222": {
					"id": "222",
					"title": "Turkmenistan",
					"iso": "TM"
				},
				"223": {
					"id": "223",
					"title": "Turks and Caicos",
					"iso": "TC"
				},
				"224": {
					"id": "224",
					"title": "Tuvalu",
					"iso": "TV"
				},
				"225": {
					"id": "225",
					"title": "Uganda",
					"iso": "UG"
				},
				"226": {
					"id": "226",
					"title": "Ukraine",
					"iso": "UA"
				},
				"227": {
					"id": "227",
					"title": "United Arab Emirates",
					"iso": "AE"
				},
				"228": {
					"id": "228",
					"title": "United States of America",
					"iso": "US"
				},
				"229": {
					"id": "229",
					"title": "United States Minor Outlying Islands",
					"iso": "UM"
				},
				"230": {
					"id": "230",
					"title": "Uruguay",
					"iso": "UY"
				},
				"231": {
					"id": "231",
					"title": "Uzbekistan",
					"iso": "UZ"
				},
				"232": {
					"id": "232",
					"title": "Vanuatu",
					"iso": "VU"
				},
				"233": {
					"id": "233",
					"title": "Vatican",
					"iso": "VA"
				},
				"234": {
					"id": "234",
					"title": "Venezuela",
					"iso": "VE"
				},
				"235": {
					"id": "235",
					"title": "Viet Nam",
					"iso": "VN"
				},
				"236": {
					"id": "236",
					"title": "Virgin Islands, british (Tortola)",
					"iso": "VG"
				},
				"237": {
					"id": "237",
					"title": "Virgin Islands (USA)",
					"iso": "VI"
				},
				"238": {
					"id": "238",
					"title": "Wallis and Futuna Islands",
					"iso": "WF"
				},
				"239": {
					"id": "239",
					"title": "Yemen",
					"iso": "YE"
				},
				"240": {
					"id": "240",
					"title": "Zambie",
					"iso": "ZM"
				},
				"241": {
					"id": "241",
					"title": "Zimbabwe",
					"iso": "ZW"
				},
				"242": {
					"id": "242",
					"title": "Switzerland",
					"iso": "CH"
				}
			},

			dataUsage: {},

			uploadProgress: 0,
			dataSyncing: false,
			stagedItemsCount: 0,

			exhibitionSelected: null,
			exhibitionDefault: 'cosmoprof_asia_hong_kong_2024',
			exhibitionList: {
				// 'cosmoprof_bologna_2023': {
				// 	name: 'Cosmoprof Bologna (2023)',
				// 	dates: '16.03-18.03.2023'
				// },
				// 'cosmoprof_asia_hong_kong_2023': {
				// 	name: 'Cosmoprof Asia Hong Kong (2023)',
				// 	dates: '15.11-17.11.2023'
				// },

				'cosmoprof_asia_hong_kong_2024': {
					name: 'Cosmoprof Asia Hong Kong (2024)',
					dates: '12.11-15.11.2024'
				},

				// 'cosmoprof_bologna_2024': {
				// 	name: 'Cosmoprof Bologna (2024)',
				// 	dates: '21.03-24.03.2024'
				// },
				'unplanned_event': {
					name: 'Unplanned event',
					dates: '-'
				}
			},

			notifications: [
				// {
				// 	type:'error',
				// 	header:'Header',
				// 	message:'Message'
				// }
			]
		}
	},
	mutations: {
		updateDatabase(state, database) {
			state.database = database
		},

		updateOnlineStatus(state, isOnline) {
			state.isOnline = isOnline
		},
		updateForceOfflineStatus(state, isOnline) {
			state.forceOffline = isOnline
		},

		updateStagedFormsData(state, formsData) {
			state.stagedFormsData = formsData
		},

		updateActiveFormIndex(state, formIndex) {
			state.activeFormIndex = +formIndex
		},
		updateActiveFormData(state, formsData) {
			if (!+formsData.formIndex) {
				formsData.filesData = []
				formsData.countriesSelected = []
				formsData.customerBusinessTypes = []
				formsData.exhibition = state.exhibitionSelected
			}
			state.activeFormData = formsData

			state.dataLoading = false
			// setTimeout(() => { state.dataLoading = false}, 1000)
		},

		setDataLoading(state, loading) {
			state.dataLoading = loading
		},

		toggleDarkMode(state, darkMode) {
			state.isDarkMode = +darkMode
			localStorage.isDarkMode = +darkMode
			if (+darkMode) {
				document.body.classList.add("dark")
				document.querySelector('meta[name="theme-color"]').setAttribute('content', '#101729')
			} else {
				document.body.classList.remove("dark")
				document.querySelector('meta[name="theme-color"]').setAttribute('content', '#F8F9FA')
			}
		},
		updateHasNewerVersion(state, value) {
			state.hasNewerVersion = value
		},
		async updateDataUsage(state, quota) {
			state.dataUsage = quota
		},

		setApiToken(state, apiToken) {
			state.apiToken = apiToken
			if (apiToken) {
				localStorage.apiToken = apiToken
			} else {
				localStorage.removeItem('apiToken')
				state.userData = {}
			}
		},
		setUserData(state, userData) {
			state.userData = userData
		}
	},
	actions: {
		init({dispatch}) {
			dispatch('initDatabase').then(() => {
				dispatch('getStagedFormsData')
			}).catch((e) => {
				console.error(e)
			})
		},
		async initDatabase({commit, state}) {
			return new Promise((resolve, reject) => {
				let db = window.indexedDB.open(state.databaseName, 1)

				db.onerror = e => {
					console.error(db.onerror)
					reject('Error opening the database.')
				}

				db.onsuccess = e => {
					commit('updateDatabase', e.target.result)
					resolve('SUCCESS')
				};

				db.onupgradeneeded = e => {
					if (e.oldVersion === 1) e.target.result.deleteObjectStore(state.databaseObjectStoreName)
					e.target.result.createObjectStore(state.databaseObjectStoreName, {keyPath: state.keyPath})
				}
			})
		},

		setApiToken({commit}, apiToken) {
			commit('setApiToken', apiToken);
		},
		setUserData({commit}, userData) {
			commit('setUserData', userData);
		},
		checkPIN({commit, dispatch, state}, PIN) {
			state.presentedPIN = +PIN
			localStorage.PIN = +PIN
			if ((+state.presentedPIN.toString().length === +state.PIN.toString().length) && (state.presentedPIN !== state.PIN)) dispatch('pushNotification', {text: 'checkPINFailed', type: 'error', persist: false})
		},

		setDataLoading({commit, state}, loading) {
			commit('setDataLoading', loading)
		},

		getStagedFormsData({commit, dispatch, state}) {
			dispatch('setDataLoading', true)
			state.database.transaction(state.databaseObjectStoreName)
				.objectStore(state.databaseObjectStoreName)
				.getAll()
				.onsuccess = async e => {
				// await dispatch('checkDataUsage')
				dispatch('setDataLoading', false)
				// setTimeout(() => { dispatch('setDataLoading', false)}, 1000)

				commit('updateStagedFormsData', e.target.result)
			}
		},
		getStagedFormData({commit, state}) {
			let formsStore = state.database.transaction(state.databaseObjectStoreName, 'readonly').objectStore(state.databaseObjectStoreName)
			let formRequest = formsStore.get(+state.activeFormIndex)

			formRequest.onerror = e => {
				console.error('Error reading form from.', e)
			}

			formRequest.onsuccess = e => {
				if (formRequest.result) {
					commit('updateActiveFormData', e.target.result)
				} else router.push('/')
			}
		},
		setActiveFormIndex({dispatch, commit}, activeFormIndex) {
			commit('updateActiveFormIndex', +activeFormIndex)

			if (!+activeFormIndex) {
				dispatch('setDefaultFormState')
				dispatch('setDataLoading', false)
			} else dispatch('getStagedFormData')
		},
		resetFormIndex({dispatch}) {
			dispatch('setActiveFormIndex', 0)
		},
		setDefaultFormState({commit, state}) {
			commit('updateActiveFormData', {...state.fromDefaults})
		},

		saveFormData({dispatch, state}, {silent, redirect}) {
			if (!silent) state.dataSaving = true
			let now = new Date().getTime()

			if (!+state.activeFormIndex) {
				const min = 1
				const max = 2000000
				state.activeFormData.formIndex = now + (Math.floor(Math.random() * (max - min) + min))
				// if (state.userData && state.userData.auth_id) state.activeFormData.formIndex += +state.userData.auth_id
				state.activeFormData.createdAt = now
			}
			//TODO: ADD RANDOM INT TO KEY
			state.activeFormData.updatedAt = now

			//dispatch('backupData', state.activeFormData)

			if (state.isOnline && state.apiToken) dispatch('sentDataToServer', {dataToSent: state.activeFormData, silent: silent, redirect: redirect})
			else dispatch('saveDataLocally', {dataToSave: state.activeFormData, silent: silent, redirect: redirect})
			if (!+state.activeFormIndex) dispatch('resetFormIndex')
			// dispatch('getStagedFormsData')
		},
		sentDataToServer({dispatch, state}, {dataToSent, silent, redirect}) {
			if (!silent) state.dataSaving = true

			let formData = new FormData()

			formData.append("form_index", dataToSent.formIndex)

			if (dataToSent.exhibition) formData.append("exhibition", dataToSent.exhibition)
			if (dataToSent.interestEVENSWISS) formData.append("interest_evenswiss", dataToSent.interestEVENSWISS)
			if (dataToSent.interestUCLS) formData.append("interest_ucls", dataToSent.interestUCLS)
			if (dataToSent.interestALOVE) formData.append("interest_alove", dataToSent.interestALOVE)
			if (dataToSent.interestSUISSETTE) formData.append("interest_suissette", dataToSent.interestSUISSETTE)

			if (dataToSent.companyName) formData.append("company_name", dataToSent.companyName)
			if (dataToSent.givenName) formData.append("given_name", dataToSent.givenName)
			if (dataToSent.familyName) formData.append("family_name", dataToSent.familyName)
			if (dataToSent.email) formData.append("email", dataToSent.email)
			if (dataToSent.country) formData.append("country_id", dataToSent.country)
			if (dataToSent.country && state.countriesData[dataToSent.country]) formData.append("country_name", state.countriesData[dataToSent.country].title)
			if (dataToSent.website) formData.append("website", dataToSent.website)
			if (dataToSent.notes) formData.append("notes", dataToSent.notes)
			if (dataToSent.countriesSelected) formData.append("countries_selected", dataToSent.countriesSelected.join(','))
			if (dataToSent.customerBusinessTypes) formData.append("customer_business_types", dataToSent.customerBusinessTypes.join(','))
			if (dataToSent.otherTypeOfBusiness) formData.append("other_type_of_business", dataToSent.otherTypeOfBusiness)

			if (+dataToSent.filesData.length) {
				for (let i = 0; i < +dataToSent.filesData.length; i++) {
					let file = dataToSent.filesData[i];
					formData.append('files[' + i + ']', file);
				}
			}

			api()
				.put(
					"form",
					formData,
					{
						headers: {
							// 'Content-Type': 'multipart/form-data',
							'Authorization': 'Bearer ' + state.apiToken
						},
						onUploadProgress: function (progressEvent) {
							state.uploadProgress = parseInt(Math.round((+progressEvent.loaded / +progressEvent.total) * 100))
						}.bind(this)
					})
				.then((response) => {
					state.dataSaving = false

					if (response.status === 200) {
						dispatch('deleteStagedForm', {index: +dataToSent.formIndex, silent: (redirect === '/add' ? true : silent), redirect: redirect})
						if (!silent) dispatch('pushNotification', {text: 'sentDataToServer', type: 'success', persist: false})
					} else dispatch('saveDataLocally', {dataToSave: dataToSent, silent: silent, redirect: redirect})
				})
				.catch(e => {
					if (e.response.status === 401) {
						dispatch('setApiToken', null)
						dispatch('setUserData', [])
					}
					dispatch('saveDataLocally', {dataToSave: dataToSent, silent: silent, redirect: redirect})
				})
				.finally(() => {
					if (!silent) state.dataSaving = false
					if (redirect) router.push(redirect).then(r => {})

					if (state.dataSyncing) {
						--state.stagedItemsCount
						if (+state.stagedItemsCount <= 0) {

							// console.log('+state.stagedItemsCount: ' + +state.stagedItemsCount)
							// console.log('+state.stagedFormsData.length: ' + +state.stagedFormsData.length)

							dispatch('pushNotification', {text: 'syncDataCompleted', type: 'success', persist: false})

							//TODO ALWAYS CORRECTLY SYNC =( +state.stagedFormsData.length no time to delete =(
							//if (+state.stagedFormsData.length) dispatch('pushNotification', {text: 'syncDataError', type: 'error', persist: false})
							//else dispatch('pushNotification', {text: 'syncDataCompleted', type: 'success', persist: false})

							state.uploadProgress = 0
							state.dataSyncing = false
						}
					}
				})
		},
		saveDataLocally({dispatch, state}, {dataToSave, silent, redirect}) {
			if (!silent) state.dataSaving = true
			let transaction = state.database.transaction(state.databaseObjectStoreName, 'readwrite').objectStore(state.databaseObjectStoreName)

			let preparedObject = {...dataToSave}
			preparedObject.filesData = [...dataToSave.filesData]
			preparedObject.countriesSelected = [...dataToSave.countriesSelected]
			preparedObject.customerBusinessTypes = [...dataToSave.customerBusinessTypes]

			if (+state.activeFormIndex) transaction.put(preparedObject)
			else transaction.add(preparedObject)

			transaction.oncomplete = e => {
				state.dataSaving = false
			}

			if (!silent) dispatch('pushNotification', {text: 'saveDataLocally', type: 'warning', persist: false})
			dispatch('getStagedFormsData')
			if (redirect) router.push(redirect).then(r => {})
			state.dataSaving = false
		},

		backupData({dispatch, state}, dataToSave) {
			dataToSave.filesData = []
			let file = new Blob([JSON.stringify(dataToSave, null, 2)],
				{
					type: 'application/pdf;charset=UTF-8'
				});
			let reader = new FileReader();
			reader.onload = function() {
				let popup = window.open();
				let link = document.createElement('a');
				link.setAttribute('href', reader.result);
				link.setAttribute('download', dataToSave.formIndex+'.pdf');
				popup.document.body.appendChild(link);
				link.click();
			}
			reader.readAsDataURL(file);
		},

		deleteStagedForm({dispatch, state}, {index, silent, redirect}) {
			let formsStore = state.database.transaction(state.databaseObjectStoreName, 'readwrite').objectStore(state.databaseObjectStoreName)
			let formRequest = formsStore.delete(+index)

			formRequest.onerror = e => {
				console.error('Error deleting.', e)
			}

			formRequest.onsuccess = e => {
				dispatch('resetFormIndex')
				dispatch('getStagedFormsData')
				if (!silent) dispatch('pushNotification', {text: 'deleteStagedForm', type: 'success', persist: false})
				if (redirect) router.push(redirect).then(r => {
				})
			}
		},
		syncData({dispatch, state}, {silent, redirect}) {
			if (+state.stagedFormsData.length) {
				state.dataSyncing = true
				state.stagedItemsCount = +state.stagedFormsData.length
				state.stagedFormsData.forEach(element => {
					if (state.isOnline && state.apiToken) dispatch('sentDataToServer', {dataToSent: element, silent: silent, redirect: redirect})
				})
			}
		},

		pushNotification({dispatch, state}, {text, type, persist}) {
			dispatch('addNotification', {text: text, type: type, persist: persist})
		},
		addNotification({dispatch, state}, {text, type, persist}) {
			let notification = {type: type, persist: persist, header: type, message: text}
			state.notifications.push(notification)
			if (!persist) setTimeout(() => dispatch('removeNotification', +state.notifications.length - 1), 3000)
		},
		removeNotification({state}, notificationIndex) {
			state.notifications.splice(notificationIndex, 1)
		},

		checkDataUsage({commit}) {
			navigator.storage.estimate().then((estimate) => {
				commit('updateDataUsage', estimate)
			})
		},
		toggleDarkMode({commit}, darkMode) {
			commit('toggleDarkMode', darkMode)
		},
	}
})
export default store
